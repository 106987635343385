import React from 'react';
import { useEffect } from 'react';
import { disconnect } from './config/firebase';

import Game from './views/game';

import './App.css';

const App = () => {

  useEffect(() => () => disconnect(), []);

  return (
    <Game />
  );

};

export default App;