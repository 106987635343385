import React from 'react';

import './styles.scss';

const Text = React.forwardRef(({ text, children }, ref) => {

    return (
        <div className='ellipse'>
            <h5 ref={ref}>{text}{children}</h5>
        </div>
    )
});

export default Text;
