import React from 'react';

import { IMAGES } from '../../shared/constant';

import './styles.scss';

const Loader = ({
    visible
}) => {
    return (
        <div className='dollar-loader-wrapper'>
            <div className={visible ? 'dollar-loader-container-visible' : 'dollar-loader-container-invisible'}>
                <img className='dollar-loader' src={IMAGES.DOLLAR_LOADER} alt='dollar-loader' />
            </div>
        </div>
    );
};

export default Loader;