import React from 'react';

import { Probability } from '../../shared/util';

import './styles.scss';

const Choices = ({
    choices,
    onChoiceChosen,
}) => {
    const handleOnClick = (nextStep) => {
        if (typeof (nextStep) === 'object') {
            let probabilityObjectsArr = [];
            let probableNextStepsArr = Object.values(nextStep);
            for (const probableNextStepInfo of probableNextStepsArr) {
                probabilityObjectsArr.push({
                    p: probableNextStepInfo.probability,
                    f: () => probableNextStepInfo.step
                });
            }
            var probabilitilized = new Probability(probabilityObjectsArr);
            let chosenNextStep = probabilitilized();
            onChoiceChosen(chosenNextStep);
        }
        else {
            onChoiceChosen(nextStep);
        }
    };
    return (
        <div className='choices-container text-center'>
            {choices.map((choice, idx) => <input
                type="button"
                className={choices.length >= 4 ? "btn btn-sec mt-4 mx-2 d-block w-100 mx-xs-0" : "btn btn-sec mt-4 mx-2"}
                key={choice.text + idx + Math.random()}
                onClick={() => handleOnClick(choice.goToStep)}
                value={choice.text}>
            </input>)}
        </div>
    );
};

export default Choices;