import React from 'react';
import CountUp from 'react-countup';

import Icon from '../icon';
import Text from '../text';

import { ICONS } from '../../shared/constant';

import { numberFormatter } from '../../shared/util';

const Balance = ({
    balance
}) => {
    return (
        <>
            <Icon iconPath={ICONS.DOLLAR}></Icon>
            <CountUp
                start={0}
                end={balance}
                duration={0.5}
                delay={0}
                formattingFn={numberFormatter}>
                {({ countUpRef }) => <Text ref={countUpRef} />}
            </CountUp>
        </>
    );
};

export default Balance;
