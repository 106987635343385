import React from 'react';

import './styles.scss';

const Icon = ({
    iconPath
}) => {
    return (
        <div className='circle text-truncate'>
            <img
                src={iconPath}
                alt="icon"
                style={{ zIndex: 99999 }} />
        </div>
    );
};

export default Icon;