import React from 'react';
import TextTransition, { presets } from "react-text-transition";

import {
    ICONS,
} from '../../shared/constant';

import Icon from '../icon';
import Text from '../text';

const Reputation = ({
    reputation
}) => {
    return (
        <>
            <Icon iconPath={ICONS.STAR} />
            <Text>
                <TextTransition
                    text={reputation}
                    springConfig={presets.wobbly}
                    direction='up'
                    delay={1.0}
                    inline={false} />
            </Text>
        </>
    );
};

export default Reputation;