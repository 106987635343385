export const ICONS = {
    STAR: require('../../assets/icons/star.svg'),
    DOLLAR: require('../../assets/icons/dollar.svg')
}

export const INITIAL_BACKGROUND = 'TITLE';

export const IMAGES = {
    TITLE: require('../../assets/images/title.jpg'),
    SUBURBAN_COMMUNITY: require('../../assets/images/suburban_community.jpg'),
    SUB_COM_TUTOR_CENT: require('../../assets/images/sub_com_tutor_cent.jpg'),
    GAP_YR_COM_COLLEGE: require('../../assets/images/gap_yr_com_college.jpg'),
    SAD_MAN: require('../../assets/images/sad_man.jpg'),
    SUB_COM_HOUSE: require('../../assets/images/sub_com_house.jpg'),
    COLLEGE_CAMPUS: require('../../assets/images/college_campus.jpg'),
    COL_CAMP_FOOTBALL_FIELD: require('../../assets/images/col_camp_football_field.jpg'),
    COL_CAMP_SAIL_BOATS: require('../../assets/images/col_camp_sail_boats.jpg'),
    COL_CAMP_TENNIS_COURTS: require('../../assets/images/col_camp_tennis_courts.jpg'),
    FBI: require('../../assets/images/fbi.jpg'),
    SUB_COM_COURTROOM: require('../../assets/images/sub_com_courtroom.jpg'),
    TV_COUCH: require('../../assets/images/tv_couch.jpeg'),
    DOLLAR_LOADER: require('../../assets/images/dollar_loader.gif')
}

export const ANIMATIONS = {
    FADE_IN_UP: "fadeInUp",
    FADE_IN_DOWN: "fadeInDown",
};

export const REPUTATION = {
    NONE: 'NONE',
    EXCELLENT: 'Excellent',
    GREAT: 'Great',
    GOOD: 'Good',
    NOT_GOOD: 'Not Good',
    BAD: 'Bad'
};