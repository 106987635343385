import React from 'react';

import SocialShare from '../social-share';

import './styles.scss';

const TitleContent = ({
    onPlayButtonClicked
}) => {
    return (
        <div className="img-overlay">
            <div className='overlay'></div>
            <button className="btn btn-outline-dark btn-primary"
                onClick={onPlayButtonClicked}>Play</button>
            {/* <SocialShare/> */}
        </div>
    );
};

export default TitleContent;