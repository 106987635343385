import React from 'react';

import './styles.css';

const BackgroundImage = ({
    imagePath,
    onImageLoaded,
}) => {
    return (
        <img
            className="img-responsive"
            alt='Background'
            onLoad={onImageLoaded}
            src={imagePath} />
    );
};

export default BackgroundImage;