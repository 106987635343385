import React from 'react';

import './styles.scss';

import Reputation from '../reputation';
import Balance from '../balance';

const Stats = ({
    reputation,
    balance,
}) => {
    return (
        <div className='stats-wrapper' >
            <div className='stats-container'>
                <div className='stats-info-wrapper'>
                    <Reputation reputation={reputation} />
                </div>
                <div className='stats-info-wrapper'>
                    <Balance balance={balance} />
                </div>
            </div>
        </div>
    );
};

export default Stats;