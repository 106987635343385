/* please see react-pretty-numbers for arguments  */
export const numberFormatter = (number, params = {
    locales: 'en-US',
    shortFormat: true
}) => {

    var nFormatter = function nFormatter(num, minValue) {

        if (!num || !+num || typeof + num !== 'number') {
            return {
                number: num
            };
        }

         num = +num;

         minValue = minValue || 0;
        var si = [{
                value: 1E18,
                symbol: "E"
            }, {
                value: 1E15,
                symbol: "P"
            }, {
                value: 1E12,
                symbol: "T"
            }, {
                value: 1E9,
                symbol: "G"
            }, {
                value: 1E6,
                symbol: "M"
            }, {
                value: 1E3,
                symbol: "k"
            }],
            // rx = /\.0+$|(\.[0-9]*[1-9])0+$/,
            i;

        if (typeof num === 'number' && num >= minValue) {
            for (i = 0; i < si.length; i++) {
                if (num >= si[i].value) {
                    // return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
                    return {
                        number: num / si[i].value,
                        letter: si[i].symbol
                    };
                }
            }
        }
        //return num.replace(rx, "$1");
        return {
            number: num
        };
    };

    var option = {
        minimumFractionDigits: 0
    };

    if (params) {
        var locales = params.locales;
        if (params.wholenumber === 'floor') {
             number = Math.floor(number);
        } else if (params.wholenumber === 'ceil') {
             number = Math.ceil(number);
        } else {
             number = +number;
        }
        var currencyIndicator;
        if (params.currencyIndicator) {
             currencyIndicator = params.currencyIndicator;
        } else {
             currencyIndicator = 'USD';
        }

        if (params.percentage) {
            option = Object.assign(option, {
                style: 'percent',
                maximumFractionDigits: params.precision || 2,
                minimumFractionDigits: params.precision || 0,
                useGrouping: params.commafy
            });
        } else if (params.currency) {
            option = Object.assign(option, {
                style: 'currency',
                currency: currencyIndicator,
                maximumFractionDigits: params.precision || 2,
                minimumFractionDigits: params.precision || 0,
                useGrouping: params.commafy
            });
        } else {
            option = Object.assign(option, {
                style: 'decimal',
                useGrouping: params.commafy
            });

            if (params.precision) {
                option.maximumFractionDigits = params.precision;
                option.minimumFractionDigits = params.precision || 0;
            }
        }

    } else {
         number = +number;
         locales = 'en-US';

    }

    var shortenNumber = number;
    var numberLetter = '';

    if (params && params.shortFormat) {
        var sn = nFormatter(number, params.shortFormatMinValue || 0);
        shortenNumber = sn.number;
        numberLetter = sn.letter || '';

        if (params.shortFormatMinValue && +number >= params.shortFormatMinValue) {
            option.maximumFractionDigits = params.shortFormatPrecision || params.precision || 0;
        }
    }

    option.minimumFractionDigits = Math.min(~~option.minimumFractionDigits, ~~option.maximumFractionDigits);

    var theFormattedNumber = shortenNumber;

    if (typeof shortenNumber === 'number') {
        theFormattedNumber = Intl.NumberFormat(locales, option).format(+shortenNumber);
    }

    if (numberLetter) {
        if (params && params.percentage) {
            theFormattedNumber = theFormattedNumber.replace('%', numberLetter + '%');
        } else {
            theFormattedNumber += numberLetter;
        }
    }
    return theFormattedNumber;
};