import React, { useState, useEffect } from 'react';
import ReactDom from 'react-dom';

import { database } from '../../config/firebase';

import BackgroundImage from '../../components/background-image';
import Stats from '../../components/stats';
import Loader from '../../components/loader';
import Scenario from '../../components/scenario';
import TitleContent from '../../components/title-content';

import { INITIAL_BACKGROUND, IMAGES, ANIMATIONS, REPUTATION } from '../../shared/constant';

import './styles.scss';

const Game = () => {

    const [currentStep, setCurrentStep] = useState('');

    const [balance, setBalance] = useState(1000000); // initial 0

    const [reputation, setReputation] = useState({
        quantitative: 0,
        qualitative: REPUTATION.EXCELLENT
    });

    const [loading, setLoading] = useState(true);

    const [background, setBackground] = useState(INITIAL_BACKGROUND);

    const [texts, setTexts] = useState(['']);
    const [choices, setChoices] = useState([{ text: '', goToStep: '' }]);
    const [bulletPoints, setBulletPoints] = useState(['']);

    const [scenarioAnimation, setScenarioAnimation] = useState(ANIMATIONS.FADE_IN_UP);

    const [toFetchGameData, fetchGameData] = useState(false);

    const [isGameStarted, gameStarted] = useState(false);

    const [isGameDataFetched, gameDataFetched] = useState(false);

    const [gameData, setGameData] = useState(null);

    // for preparing initial step
    useEffect(() => {
        if (isGameDataFetched) {
            setLoading(false);
            moveToNextStep();
        }
    }, [isGameDataFetched]);

    // for fetching game data
    useEffect(() => {
        if (toFetchGameData) {
            database.ref().once('value', (snpshot) => {
                ReactDom.unstable_batchedUpdates(() => {
                    gameDataFetched(true);
                    setGameData(snpshot.val());
                    setCurrentStep(snpshot.val().initial_step);
                });
            });
        }
    }, [toFetchGameData]);

    const resetStats = () => {
        setBalance(gameData.initialBalance);
        setReputation({
            quantitative: gameData.initialReputation,
            qualitative: convertQuantitativeReputationToQualitative(gameData.initialReputation)
        })
    };

    const convertQuantitativeReputationToQualitative = (quantitativeReputation) => {
        if (quantitativeReputation >= 95) {
            return REPUTATION.EXCELLENT;
        }

        else if (quantitativeReputation >= 90) {
            return REPUTATION.GREAT;
        }

        else if (quantitativeReputation >= 80) {
            return REPUTATION.GOOD;
        }

        else if (quantitativeReputation >= 60) {
            return REPUTATION.NOT_GOOD;
        }

        else {
            return REPUTATION.BAD;
        }
    };

    const onPlayButtonClicked = () => {
        fetchGameData(true);
        setLoading(true);
    }

    const onAnimationEnd = (event) => {
        if (event.animationName === ANIMATIONS.FADE_IN_DOWN) {
            moveToNextStep();
        }
    };

    const onChoiceChosen = (nextStep) => {
        setCurrentStep(nextStep);
        setScenarioAnimation(ANIMATIONS.FADE_IN_DOWN);
        // nextStep === gameData.startOverStep ? resetStats() : prepareBalanceForNextStep(nextStep);
    };

    const moveToNextStep = () => {
        gameData[currentStep].background === background ? renderNextStep() : loadBgForNextStep()
    };

    const renderNextStep = () => {
        prepareTextForNextStep();
        // gameStarted ? prepareReputationForNextStep() : resetStats();
        gameStarted(true);
    };

    const prepareTextForNextStep = () => {
        const currentStepInfo = gameData[currentStep];
        setTexts(Object.values(currentStepInfo.texts));
        setChoices(Object.values(currentStepInfo.choices));
        setScenarioAnimation(ANIMATIONS.FADE_IN_UP);
        currentStepInfo.bulletPoints ? setBulletPoints(Object.values(currentStepInfo.bulletPoints)) : setBulletPoints(['']);
    };

    const prepareBalanceForNextStep = (nextStep) => {
        const nextStepInfo = gameData[nextStep];
        if (nextStepInfo.cost) {
            setBalance((prevBalance) => prevBalance - nextStepInfo.cost);
        }
    };

    const prepareReputationForNextStep = () => {
        const currentStepInfo = gameData[currentStep];
        if (currentStepInfo.reputation) {
            setReputation((prevReputation) => {
                let newQuantitativeReputation = prevReputation.quantitative + currentStepInfo.reputation;
                return {
                    quantitative: newQuantitativeReputation,
                    qualitative: convertQuantitativeReputationToQualitative(newQuantitativeReputation)
                };
            });
        }
    };

    const loadBgForNextStep = () => {
        setLoading(true);
        setBackground(gameData[currentStep].background);
    };

    const onBgLoadedForNextStep = () => {
        setLoading(false);
        if (isGameDataFetched) {
            renderNextStep();
        }
    };

    return (
        <div className="game-container">
            <BackgroundImage
                imagePath={IMAGES[background]}
                onImageLoaded={onBgLoadedForNextStep} />
            {isGameStarted ? <Stats
                reputation={reputation.qualitative}
                balance={balance} /> : null}
            <Loader visible={loading} />
            {loading ? null : isGameStarted ?
                <Scenario
                    texts={texts}
                    bulletPoints={bulletPoints}
                    choices={choices}
                    animation={scenarioAnimation}
                    onAnimationEnd={onAnimationEnd}
                    onChoiceChosen={onChoiceChosen} />
                : <TitleContent onPlayButtonClicked={onPlayButtonClicked} />}
        </div>
    );
};

export default Game;