import React, { useEffect } from 'react';
import $ from 'jquery';

import './styles.scss';

import Choices from '../choices';

const Scenario = ({
    texts,
    bulletPoints,
    choices,
    onChoiceChosen,
    animation,
    onAnimationEnd
}) => {
    useEffect(() => {
        window.scrollTo(0, document.body.scrollHeight);
        $("#text-content").animate({ scrollTop: 0 }, "fast");
    }, [animation]);

    return (
        <div className={`text-block ${animation}`} id='text-content'
            onAnimationEnd={onAnimationEnd}>
            <div className="content">
                <div className='center-text'>
                    {texts.map((text, idx) => <p key={idx}>{text}</p>)}
                </div>
                <div className='bullet-point'>
                    {bulletPoints.map((text, idx) => <p key={idx}>{text}</p>)}
                </div>
                <Choices
                    choices={choices}
                    onChoiceChosen={onChoiceChosen} />
            </div>
        </div>
    );
};

export default Scenario;

